<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <!-- {{menuItems}} -->
      @for (item of menuItems; track $index) {
      <ng-container>
        @if(item.isTitle){
        <li class="menu-title"><i class="bx {{item.icon}}"></i> {{ item.label | translate }} 1</li>
        }@else if(!item.isTitle && !item.isLayout){
        <li>
          @if(hasItems(item)){
          <a href="javascript:void(0);" class="is-parent" [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
            @if(item.icon){
            <i class="{{item.icon}}"></i>
            } 
            <span> {{ item.label | translate }}
               <ng-container *ngIf="item.label === 'Lead Management' && leadDataCount>0">
              <span class="badge rounded-pill bg-danger ms-2">
                {{leadDataCount}}
              </span>
            </ng-container>
            <ng-container *ngIf="item.label === 'Whatsapp'&& contactCount>0 ">
              <span class="badge rounded-pill bg-danger ms-2">
                {{contactCount}}
              </span>
            </ng-container>
          </span>
            @if(item.badge){
            <span class="badge rounded-pill float-end bg-{{item.badge.variant}}">{{item.badge.text |
              translate}}</span>
            }
          </a>}
          @else{
          <a routerLink="{{item.link}}" class="side-nav-link-ref" routerLinkActive="active">
            @if(item.icon){
            <i class="bx {{ item.icon }}"></i>
            }
            <span>       
              {{ item.label | translate }}
              
              
            </span>
            @if(item.badge){
            <span class="badge rounded-pill bg-{{item.badge.variant}} float-end">{{item.badge.text |
              translate}}</span>
            }
          </a>
          }
          @if(hasItems(item)){
          <ul class="sub-menu" aria-expanded="false">
            @for (subitem of item.subItems; track $index) {
            <li>
              @if(hasItems(subitem)){
              <a class="side-nav-link-a-ref has-arrow" href="javascript:void(0);" [attr.data-parent]="subitem.parentId">
               {{ subitem.label | translate}} 
              </a>
              <ul class="sub-menu mm-collapse" aria-expanded="false">
                @for (subSubitem of subitem.subItems; track $index) {
                <li>
                  <a [attr.data-parent]="subSubitem.parentId" routerLink="{{subSubitem.link}}" routerLinkActive="active" class="side-nav-link-ref">
                    {{ subSubitem.label | translate }} 
                  </a>
                </li>
                }
              </ul>}
              @else{
              <a routerLink="{{subitem.link}}" class="side-nav-link-ref" [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                  {{ subitem.label | translate}} 
                  <ng-container *ngIf="subitem.label === 'Lead' && leadDataCount>0">
                    <span class="badge rounded-pill bg-danger ms-2">
                      {{leadDataCount}}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="subitem.label === 'Live Chat' && contactCount>0">
                    <span class="badge rounded-pill bg-danger ms-2">
                      {{contactCount}}
                    </span>
                  </ng-container>
                  
                @if(subitem.badge){
                <span class="badge rounded-pill bg-{{subitem.badge.variant}} float-end">{{subitem.badge.text |
                  translate}} </span>
                }
              </a>
              }
            </li>
            }
          </ul>
          }
        </li>
        }
      </ng-container>
      }
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    @if(isCondensed){
    <div>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    }@else {
    <div>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    }
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->